import React from "react";
import { Helmet } from 'react-helmet';

export const HomePage = () => {
  return (
    <div>
    <Helmet>
        <title>DYOR Crypto 101 Accelerator</title>
        <meta name="description" content="Empower your journey into the world of cryptocurrency with DYOR’s one-month intensive bootcamp." />
        <meta property="og:title" content="DYOR Crypto 101 Accelerator" />
        <meta property="og:description" content="Empower your journey into the world of cryptocurrency with DYOR’s one-month intensive bootcamp." />
        <meta property="og:image" content="https://www.yoursite.com/images/banner.jpg" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="DYOR Crypto 101 Accelerator" />
        <meta property="twitter:description" content="Empower your journey into the world of cryptocurrency with DYOR’s one-month intensive bootcamp." />
        <meta property="twitter:image" content="https://www.yoursite.com/images/banner.jpg" />
      </Helmet>
    <div className="flex flex-col min-h-screen mx-auto max-w-[1011px] px-6">
      {/* Top section for logo */}
      <div className="bg-white px-8 py-4 flex items-center">
        <img src="/logodyor.png" alt="DYOR Crypto 101 Accelerator Logo" className="max-h-[50px]" />
      </div>

      {/* Middle section for navigation */}
      <nav className="px-8 py-2 font-custom font-semibold">
        <ul className="flex justify-center space-x-12">
          <li className="text-gray-500 text-lg hover:text-gray-900 cursor-pointer">
            <a href="https://jyyv4lbv8i3.typeform.com/to/kCAEUCU6" target="_blank" rel="noopener noreferrer">Join Now</a>
          </li>
          <li className="text-gray-500 text-lg hover:text-gray-900 cursor-pointer">
            <a href="mailto:joindyor@gmail.com">Contact</a>
          </li>
        </ul>
      </nav>

      {/* Main section */}
      <MainSection />

      {/* Footer section */}
      <footer className="bg-white px-8 py-4 mt-auto">
        <p className="text-center text-gray-600">
          DYOR Crypto 101 Accelerator. All rights reserved.
        </p>
      </footer>
    </div>
    </div>
  );
}

const MainSection = () => {
  return (
    <div className="flex-grow px-8 py-12 flex flex-col font-custom">
      <p className="max-w-screen-lg text-xl md:text-3xl font-bold">
        <span>DYOR Crypto 101 Accelerator</span>
      </p>
      <p className="max-w-screen-lg text-lg font-medium mt-8">
        <span>Empower your journey into the world of cryptocurrency with DYOR’s one-month intensive bootcamp.</span>
      </p>
      <p className="max-w-screen-lg text-lg font-medium mt-8">
      <span>Designed for newcomers, our bootcamp offers you hands-on experience and lifetime access to a community dedicated to demystifying building and investing in crypto.</span>
      </p>
      <p className="max-w-screen-lg text-lg font-medium mt-8">
      <span>Unlock your potential in the digital economy—start your DYOR journey today!</span>
      </p>
    </div>
  );
};

export default HomePage;
